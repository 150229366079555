import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import {
  SAVE_ASSESSMENT_QUESTION,
  UPDATE_ASSESSMENT_QUESTION,
} from "./graphql";
import { useDebouncedCallback } from "use-debounce";
import { InlineSpinner } from "components/Spinner";
import { BsTrash, BsUpload } from "react-icons/bs";
import { FETCH_PRESIGNED_URL, uploadFile } from "utils/upload";
import { toast } from "react-toastify";
import CharlesButton from "components/charles/base";
import { FileSelector, Input, Text } from "components/Form";
import { SCORE_COLORS } from "./const";
import ImprovementsView from "./ImprovementsView";
import { Alert } from "components/Toast";
import useAssessmentOperations from "./useAssessmentOperations";
import InternalNoteView from "./InternalNoteView";
import AssessmentQuestionTags from "./AssessmentQuestionTags";

function hasChoices(question) {
  return (
    question.answerType === "SINGLE_CHOICE" ||
    question.answerType === "MULTIPLE_CHOICES"
  );
}

const AssessmentQuestion = ({ assessmentQuestion, requiredSupplier }) => {
  const { confirmAssessmentQuestion, showInternalNoteForm } =
    useAssessmentOperations();
  const numberListCount = assessmentQuestion.question.numberListCount;
  const [answer, setAnswer] = useState({
    answerNumbers: assessmentQuestion.answerNumbers.length
      ? assessmentQuestion.answerNumbers
      : Array(numberListCount).fill(0),
    answerText: assessmentQuestion.answerText,
    answerChoices: assessmentQuestion.answerChoices,
    applicable: assessmentQuestion.applicable,
  });
  const [improvementNote, setImprovementNote] = useState(
    assessmentQuestion.improvementNote,
  );

  const [save, saveRes] = useMutation(SAVE_ASSESSMENT_QUESTION, {
    variables: { id: assessmentQuestion.id, ...answer, improvementNote },
    onCompleted() {
      Alert("success", "Question updated.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const [update, updateRes] = useMutation(UPDATE_ASSESSMENT_QUESTION, {
    onCompleted() {
      Alert("success", "Auto saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const debouncedSave = useDebouncedCallback(save, 800);
  const debouncedUpdate = useDebouncedCallback(update, 800);

  function handleUpdate(values) {
    update({
      variables: { id: assessmentQuestion.id, ...values },
      optimisticResponse: {
        updateAssessmentQuestion: {
          assessmentQuestion: { ...assessmentQuestion, ...values },
        },
      },
    });
  }

  const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          fetchPresignedUrl({ variables: { filename: file.name } })
            .then((res) => uploadFile(res.data.qiniuPresignedUrl, file))
            .then(onCompleted)
            .catch(onError);
        }),
      );
    });

    Promise.all(promises)
      .then((urls) => {
        save({
          variables: {
            id: assessmentQuestion.id,
            ...answer,
            uploadedFiles: [...assessmentQuestion.uploadedFiles, ...urls],
          },
        });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  function tryDeleteFile(url) {
    if (
      window.confirm(
        "Are you sure to delete this file? 你确定要删除这个文件吗?",
      ) === false
    )
      return;
    const newUploadedFiles = assessmentQuestion.uploadedFiles.filter(
      (u) => u !== url,
    );
    save({
      variables: {
        id: assessmentQuestion.id,
        ...answer,
        uploadedFiles: newUploadedFiles,
      },
    });
  }

  return (
    <div className="relative">
      <div className="text-5xl font-black float-right -mt-2 pl-4 font-serif">
        <div className={SCORE_COLORS[assessmentQuestion.score]}>
          {assessmentQuestion.score}
        </div>
      </div>

      <div className="flex justify-between space-x-4">
        <div>
          <label htmlFor="">{assessmentQuestion.question.indexNumber}:</label>{" "}
          <span className="whitespace-pre-wrap">
            {assessmentQuestion.question.question}
          </span>
          {assessmentQuestion.question.answerType === "MULTIPLE_CHOICES" ? (
            <span className=" opacity-70">(Multiple Choice)</span>
          ) : assessmentQuestion.question.answerType === "SINGLE_CHOICE" ? (
            <span className=" opacity-70">(Single Choice)</span>
          ) : null}
          <div
            className={`relative inline-block translate-y-[2px] pl-1 transition-all duration-200
            ${
              saveRes.loading || updateRes.loading ? "opacity-100" : "opacity-0"
            }
            `}
          >
            <InlineSpinner text={null} size={14} />
          </div>
          {assessmentQuestion.question.standardDescription ? (
            <div className="text-xs opacity-70">
              <label htmlFor="">Standard: </label>
              {assessmentQuestion.question.standardDescription}
            </div>
          ) : null}
          <AssessmentQuestionTags assessmentQuesetion={assessmentQuestion} />
        </div>
      </div>

      {assessmentQuestion.question.applicableRequired && (
        <div className="mt-2 flex">
          <label
            htmlFor={`applicable-${assessmentQuestion.id}`}
            className="flex items-center space-x-2 cursor-pointer"
          >
            <input
              id={`applicable-${assessmentQuestion.id}`}
              type="checkbox"
              checked={answer.applicable}
              onChange={() => {
                const applicable = !answer.applicable;
                setAnswer((prev) => ({ ...prev, applicable }));
                debouncedSave({
                  variables: {
                    id: assessmentQuestion.id,
                    ...answer,
                    applicable,
                  },
                });
              }}
            />
            <span>{assessmentQuestion.question.applicableDescription}</span>
          </label>
        </div>
      )}

      {!assessmentQuestion.question.applicableRequired ||
      (assessmentQuestion.question.applicableRequired && answer.applicable) ? (
        <>
          {hasChoices(assessmentQuestion.question) ? (
            <div className="my-2 space-y-2 ">
              {assessmentQuestion.question.choices.map((choice) => (
                <div
                  key={choice.id}
                  className={`flex items-baseline space-x-3`}
                >
                  <div
                    className={`w-6 h-6 border dark:border-gray-400 rounded-full flex items-center justify-center flex-shrink-0 text-xs cursor-pointer transition-all duration-200
                    ${
                      answer.answerChoices.includes(choice.choice)
                        ? "bg-blue-500 active:bg-blue-100 text-white active:text-gray-800 dark:bg-blue-600 dark:text-white dark:active:text-gray-400 dark:active:bg-gray-800"
                        : "active:bg-blue-600"
                    }
                  `}
                    onClick={() => {
                      let choices = [];
                      if (
                        assessmentQuestion.question.answerType ===
                        "SINGLE_CHOICE"
                      ) {
                        choices = [choice.choice];
                      } else if (
                        assessmentQuestion.question.answerType ===
                        "MULTIPLE_CHOICES"
                      ) {
                        if (answer.answerChoices.includes(choice.choice)) {
                          choices = answer.answerChoices.filter(
                            (c) => c !== choice.choice,
                          );
                        } else {
                          choices = [...answer.answerChoices, choice.choice];
                        }
                      }
                      setAnswer((prev) => ({
                        ...prev,
                        answerChoices: choices,
                      }));
                      debouncedSave({
                        variables: {
                          id: assessmentQuestion.id,
                          ...answer,
                          answerChoices: choices,
                        },
                      });
                    }}
                  >
                    {choice.choice}
                  </div>
                  <div className=" group-hover:text-blue-500">
                    {choice.content}
                  </div>
                </div>
              ))}
            </div>
          ) : assessmentQuestion.question.answerType === "TEXT" ? (
            <div className="mt-2">
              <Text
                placeholder="Input here. 请在此输入你的回答。"
                value={answer.answerText || ""}
                onChange={(e) => {
                  const answerText = e.target.value;
                  setAnswer((prev) => ({ ...prev, answerText }));
                  debouncedSave({
                    variables: {
                      id: assessmentQuestion.id,
                      ...answer,
                      answerText,
                    },
                  });
                }}
              />
            </div>
          ) : numberListCount ? (
            <div className="mt-2">
              <div className="flex space-x-3 items-center">
                <label htmlFor="">Answer:</label>
                {answer.answerNumbers.map((i, index) => (
                  <div key={index}>
                    <Input
                      value={i}
                      onChange={(e) => {
                        const newAnswerNumbers = [...answer.answerNumbers];
                        newAnswerNumbers[index] = e.target.value;
                        setAnswer((prev) => ({
                          ...prev,
                          answerNumbers: newAnswerNumbers,
                        }));
                        debouncedSave({
                          variables: {
                            id: assessmentQuestion.id,
                            ...answer,
                            answerNumbers: newAnswerNumbers,
                          },
                        });
                      }}
                      className="text-center w-24"
                    />
                  </div>
                ))}
              </div>
              {assessmentQuestion.question.numberListCount > 1 ? (
                <div className="text-xs opacity-70 mt-2">
                  Please input the answer in order. 请按顺序输入答案。
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex items-baseline mt-1 space-x-2">
              <label htmlFor="">Answer: </label>
              <div>{assessmentQuestion.answer}</div>
            </div>
          )}
        </>
      ) : null}

      <div className="text-xs font-semibold mt-4 space-y-2">
        {assessmentQuestion.uploadedFiles.length > 0 ? (
          <div className="text-xs space-y-2">
            {assessmentQuestion.uploadedFiles.map((url, index) => (
              <div key={index} className="flex items-center space-x-2">
                <CharlesButton danger onClick={() => tryDeleteFile(url)}>
                  <BsTrash />
                </CharlesButton>
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </div>
            ))}
          </div>
        ) : null}

        {assessmentQuestion.question.category?.requiredSupplier ? (
          <div className="flex items-center justify-between">
            <div className="xl:flex xl:space-x-6 space-y-3 xl:space-y-0">
              <div className="flex space-x-6 items-center">
                <FileSelector
                  uploading={isUploading}
                  title={
                    <div className="flex items-center space-x-2">
                      <span>
                        <BsUpload />
                      </span>
                      <span>Upload file / image</span>
                    </div>
                  }
                  onChange={(e) => {
                    const files = [...e.target.files];
                    handleFileUpload(files);
                    e.target.value = null;
                  }}
                />

                <CharlesButton
                  onClick={() => confirmAssessmentQuestion(assessmentQuestion)}
                >
                  {assessmentQuestion.score ? "Change Score" : "Set Score"}
                </CharlesButton>

                {!assessmentQuestion.internalNote && (
                  <CharlesButton
                    onClick={() => showInternalNoteForm(assessmentQuestion)}
                  >
                    + Internal Note
                  </CharlesButton>
                )}

                <label
                  htmlFor={`followUpNextTime-${assessmentQuestion.id}`}
                  className="flex items-center space-x-1 cursor-pointer"
                >
                  <span>Follow up next time: </span>
                  <input
                    id={`followUpNextTime-${assessmentQuestion.id}`}
                    type="checkbox"
                    checked={assessmentQuestion.followUpNextTime}
                    onClick={() =>
                      handleUpdate({
                        followUpNextTime: !assessmentQuestion.followUpNextTime,
                      })
                    }
                  />
                </label>
              </div>

              <div className="flex space-x-6">
                <label
                  htmlFor={`requiredImprovement-${assessmentQuestion.id}`}
                  className="flex items-center space-x-1 cursor-pointer"
                >
                  <span>Required Improvement: </span>
                  <input
                    id={`requiredImprovement-${assessmentQuestion.id}`}
                    type="checkbox"
                    checked={assessmentQuestion.requiredImprovement}
                    onClick={() =>
                      handleUpdate({
                        requiredImprovement:
                          !assessmentQuestion.requiredImprovement,
                      })
                    }
                  />
                </label>

                {assessmentQuestion.requiredImprovement ? (
                  <div className="flex items-center space-x-3">
                    <label
                      htmlFor={`improvementCompleted-${assessmentQuestion.id}`}
                      className="flex items-center space-x-1 cursor-pointer"
                    >
                      <span>Improvement Completed: </span>
                      <input
                        id={`improvementCompleted-${assessmentQuestion.id}`}
                        type="checkbox"
                        checked={assessmentQuestion.improvementCompleted}
                        onClick={() =>
                          handleUpdate({
                            improvementCompleted:
                              !assessmentQuestion.improvementCompleted,
                          })
                        }
                      />
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {assessmentQuestion.requiredImprovement ? (
          <div>
            <label htmlFor="">Improvement Note</label>
            <Text
              className="mt-2"
              value={improvementNote || ""}
              onChange={(e) => {
                setImprovementNote(e.target.value);
                debouncedUpdate({
                  variables: {
                    id: assessmentQuestion.id,
                    improvementNote: e.target.value,
                  },
                });
              }}
            />
            <div className="font-normal opacity-70">
              Provide clear improvement feedback for supplier to follow up.
            </div>
          </div>
        ) : null}
      </div>

      {assessmentQuestion.requiredImprovement ? (
        <ImprovementsView
          improvements={assessmentQuestion.improvements.edges}
        />
      ) : null}

      <InternalNoteView assessmentQuestion={assessmentQuestion} />
    </div>
  );
};

export default AssessmentQuestion;
