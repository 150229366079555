import React, { Fragment } from "react";
import { BsArrowRight } from "react-icons/bs";

const Status = ({ status, active = true }) => {
  let styles = {
    OVER_DUE: "bg-red-500",
    PREPARE_PAYMENT: "bg-orange-500",
    PARTIALLY_PAID: "bg-teal-500",
    FULLY_PAID: "bg-green-500",
    PENDING: "bg-blue-600",
    PROCESSING: "bg-teal-600",
    PASS: "bg-green-500",
    SUCCESS: "bg-green-500",
    FAILED: "bg-red-600",

    DRAFT: "bg-gray-500 dark:bg-gray-800 dark:text-gray-400",
    WAITING_TO_CONFIRM: "bg-teal-500",
    PLANNING: "bg-green-500",
    HANDLED: "bg-sky-700",
    CANCELED: "bg-red-600",
    CANCELLED: "bg-red-600",

    SELF_ASSESSMENT: "bg-teal-500",
    REVIEWING: "bg-indigo-500",
    IMPROVING: "bg-orange-500",
    COMPLETED: "bg-sky-700",
    CONFIRMED: "bg-green-500",

    REJECTED: "bg-red-600",
    ACCEPTED: "bg-green-500",

    IN_PRODUCTION: "bg-teal-500",
    SHIPPED: "bg-sky-500",
    DELIVERED: "bg-green-500",

    AWAITING_PAYMENT: "bg-orange-500",
    PAID: "bg-green-500",
    RELEASED: "bg-green-500",
    RUNNING_CHANGE: "bg-teal-500",

    AWAITING: "bg-blue-500",
    LOADING: "bg-orange-500",
    ACKNOWLEDGED: "bg-green-500",

    DONE: "bg-sky-700",
    ASSIGNED: "bg-teal-500",
  };

  if (!status) return " - ";

  const style = styles[status] || "bg-gray-500";

  const statusString = status.replaceAll("_", " ").toLowerCase();

  return (
    <span
      className={`text-xs rounded-full inline-block text-gray-100 px-4 py-1 scale-90 capitalize whitespace-nowrap ${style} ${active ? "" : " opacity-40"}`}
    >
      {statusString}
    </span>
  );
};

export const PLAN_STATUS_LIST = [
  "DRAFT",
  "WAITING_TO_CONFIRM",
  "PLANNING",
  "HANDLED",
];

export const ProductionPlanStatusChain = ({ status }) => {
  if (status === "CANCELED") return <Status status="CANCELED" />;

  return (
    <div className="flex items-center space-x-2">
      {PLAN_STATUS_LIST.map((i, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                PLAN_STATUS_LIST.indexOf(status) >= index ? "" : " opacity-30"
              }
            />
          )}
          <Status
            status={i}
            active={PLAN_STATUS_LIST.indexOf(status) >= index}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export const PRODUCT_LIST_STATES = ["DRAFT", "CONFIRMED"];

export const ProductListStateChain = ({ status }) => {
  if (status === "CANCELED") return <Status status="CANCELED" />;

  return (
    <div className="flex items-center space-x-2">
      {PRODUCT_LIST_STATES.map((i, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                PRODUCT_LIST_STATES.indexOf(status) >= index
                  ? ""
                  : " opacity-30"
              }
            />
          )}
          <Status
            status={i}
            active={PRODUCT_LIST_STATES.indexOf(status) >= index}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export const ORDER_STATES = ["DRAFT", "PLANNING", "HANDLED"];

export const OrderStatusChain = ({ status }) => {
  if (status === "CANCELED") return <Status status="CANCELED" />;
  return (
    <div className="flex items-center space-x-2">
      {ORDER_STATES.map((i, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                ORDER_STATES.indexOf(status) >= index ? "" : " opacity-30"
              }
            />
          )}
          <Status status={i} active={ORDER_STATES.indexOf(status) >= index} />
        </React.Fragment>
      ))}
    </div>
  );
};

export const PACKAGE_STATUS_LIST = ["SHIPPED", "DELIVERED"]; // ["DRAFT", "CONFIRMED", "SHIPPED", "DELIVERED"];
export const PackageStatusChain = ({ status }) => {
  // if (status === "CANCELED") return <Status status="CANCELED" />;
  return (
    <div className="flex items-center space-x-2">
      {PACKAGE_STATUS_LIST.map((i, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                PACKAGE_STATUS_LIST.indexOf(status) >= index
                  ? ""
                  : " opacity-30"
              }
            />
          )}
          <Status
            status={i}
            active={PACKAGE_STATUS_LIST.indexOf(status) >= index}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const RETAILER_ORDER_STATUS = [
  "AWAITING_PAYMENT",
  "VALIDATING_PAYMENT",
  "PAID",
  "SHIPPING",
  "DELIVERED",
];

export const RetailerOrderStatusChain = ({ status }) => {
  if (status === "CANCELED") return <Status status="CANCELED" />;

  console.log("RetailerOrderStatusChain", status);

  return (
    <div className="flex items-center space-x-3">
      {RETAILER_ORDER_STATUS.map((i, index) => (
        <Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                RETAILER_ORDER_STATUS.indexOf(status) >= index
                  ? ""
                  : "opacity-30"
              }
            />
          )}
          <Status
            status={i}
            active={RETAILER_ORDER_STATUS.indexOf(status) >= index}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default Status;
