import CharlesButton from "components/charles/base";
import Status from "components/Status";
import { useModals } from "ModalProvider";
import moment from "moment";
import SupplierStockDeliveryForm from "./SupplierStockDeliveryForm";

const ProductStocksTableView = ({ product, rows }) => {
  const modal = useModals();

  function updateDelivery(row) {
    modal.present({
      title: "Update Stock Delivery",
      children: (
        <SupplierStockDeliveryForm
          id={row.ss_id}
          hide={modal.hide}
          initData={{
            supplierId: row.supplier_id,
            product,
            readyDate: row.date,
            qty: row.qty,
            remark: row.remark,
          }}
          complete={modal.hide}
        />
      ),
    });
  }

  return (
    <table className="bg-white dark:bg-gray-800 font-code">
      <thead>
        <tr className="sticky top-0 bg-gray-50 dark:bg-gray-900 z-10">
          <th className="px-6 py-3">Date / 日期</th>
          <th className="px-6">Reference</th>
          <th className="px-6"></th>
          <th className="px-6 text-right">QTY / 数量</th>
          <th className="px-6 text-right">Forecast / 预计剩余数量</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            className={`border-y border-gray-100 dark:border-gray-700
              ${row.type === "SS" ? "bg-blue-100 font-semibold sticky top-10 z-10" : ""}
          `}
          >
            <td className="px-6">{moment(row.date).format("YYYY-MM-DD")}</td>
            <td className="px-6 w-full">
              <div className="flex items-center space-x-2">
                <div className="flex flex-col items-start">
                  <div>
                    {row.type === "SS" ? (
                      <div className="flex justify-end">
                        <CharlesButton onClick={() => updateDelivery(row)}>
                          {row.origin}
                        </CharlesButton>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <div>
                          [{row.type}] {row.origin}
                        </div>
                        <Status status={row.state.toUpperCase()} />
                      </div>
                    )}
                  </div>
                  {row.remark && (
                    <div className=" font-normal text-xs">{row.remark}</div>
                  )}
                </div>
              </div>
            </td>
            <td className="px-6 w-full">
              {row.po?.name}
              {row.po ? `(${row.po.qty})` : null}
            </td>
            <td className="px-6 text-right">{row.qty}</td>
            <td className="px-6 text-right">{row.cumsum}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="sticky bottom-0 z-20 bg-gray-50 dark:bg-gray-900">
          <td colSpan="4"></td>
          <td className="px-6 text-right font-bold text-xl">
            {rows[rows.length - 1].cumsum || 0}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ProductStocksTableView;
