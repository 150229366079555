import { useEffect } from "react";
import { useRef } from "react";

const useAutoScrollToBottom = (data) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        // behavior: "smooth",
      });
    }
  }, [data]);

  return ref;
};

export default useAutoScrollToBottom;
